<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-col :span="8">
      <el-button
        class="float-left"
        round
        v-if="data.date_times_previous"
        @click="fetch(data.date_times_previous)"
      >
        <font-awesome-icon icon="long-arrow-alt-left" />Indietro
      </el-button>
      <el-button class="float-left" round v-else disabled>
        <font-awesome-icon icon="long-arrow-alt-left" />Indietro
      </el-button>
    </el-col>
    <el-col :span="8" class="text-center titleCol">
      <span :class="{ 'deleted-row': registry.enabled == 0 }">
        <b class="title" v-if="data.date_time_current">
          {{ data.date_time_current }}
        </b>
      </span>
      <br />
      <small v-if="data.items">{{ data.items.full_creator }}</small>
    </el-col>
    <el-col :span="8">
      <el-button
        type="primary"
        round
        class="float-right"
        v-if="data.date_times_next"
        @click="fetch(data.date_times_next)"
      >
        Avanti
        <font-awesome-icon icon="long-arrow-alt-right" />
      </el-button>
    </el-col>
    <el-col :span="24" style="display: flex; justify-content: flex-end">
      <el-button
        type="warning"
        round
        @click="cancel()"
        :loading="submitLoading"
        v-if="registry.user && user.id == registry.user.id && registry.enabled"
        style="margin: 0 2.5px"
        >Annulla</el-button
      >
      <el-button
        type="primary"
        round
        @click="fetch()"
        :loading="submitLoading"
        v-if="registry.enabled && editableRegistryId == registry.id"
        style="margin: 0 2.5px"
        >Reset</el-button
      >
      <el-button
        type="success"
        round
        @click="submit()"
        :loading="submitLoading"
        v-if="registry.enabled && editableRegistryId == registry.id"
        style="margin: 0 2.5px"
        >Salva</el-button
      >
    </el-col>
    <el-col :span="24" class="margin-top" v-show="!loading">
      <el-form :disabled="editableRegistryId != registry.id">
        <!-- modale scheda infermieristica  -->
        <nursing-card
          :form="form"
          :config="config"
          :showNursingCard="showNursingCard"
          :protections="protections"
          :woundsDecubito="woundsDecubito"
          :woundsOthers="woundsOthers"
          :dietType="dietType"
          @close="closeModal"
          @save="submit()"
        ></nursing-card>
        <div class="trigger-intercept"></div>
        <!-- sezioni varie -->
        <el-tabs
          tab-position="right"
          style="height: 200px"
          :before-leave="
            (activeName, oldActiveName) => {
              if (activeName == 'scheda_infermieristica') {
                openModal(activeName);
                return false;
              } else {
                return true;
              }
            }
          "
          class="is-sticky"
        >
          <!-- tab generale -->
          <el-tab-pane label="Generale">
            <el-row :gutter="20" class="no-margin-row">
              <el-col :span="24" :md="12">
                <el-form-item
                  label="Anamnesi Infermieristica"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.anamnesi_infermieristica_updated_at, form.anamnesi_infermieristica_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.anamnesi_infermieristica"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="12">
                <el-form-item label="Esame Obiettivo" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.esame_obiettivo_updated_at, form.esame_obiettivo_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.esame_obiettivo"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="12">
                <el-form-item label="Allergie" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.allergie_updated_at, form.allergie_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.allergie"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="12">
                <el-form-item label="Note Rilevanti" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.note_rilevanti_updated_at, form.note_rilevanti_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    v-model="form.note_rilevanti"
                    type="textarea"
                    :rows="4"
                    prefix-icon="fas fa-user"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Altezza (cm)" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.altezza_updated_at, form.altezza_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input-number
                    v-model="form.altezza"
                    :min="0"
                    :max="300"
                    @change="updateBMI"
                  ></el-input-number>
                </el-form-item>
            </el-col>
              <el-col :span="8">
                <el-form-item label="Peso (Kg)" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.peso_updated_at, form.peso_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input-number
                    v-model="form.peso"
                    :min="0"
                    :max="500"
                    @change="updateBMI"
                    disabled
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="BMI" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.bmi_updated_at, form.bmi_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input readonly v-model="form.bmi"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <span v-if="showPatientAlert">
                    <font-awesome-icon icon="exclamation" class="danger-text" />
                    <font style="color: red"
                        >Attenzione: si sta inserendo un'altezza non conforme per il calcolo del BMI</font
                    >
                </span>
            </el-col>
              <el-col :span="24">
                <el-form-item label="MUST" class="long-label">
                  <el-input readonly v-model="testMustResult"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Stato Nutrizionale" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.stato_nutrizionale_updated_at, form.stato_nutrizionale_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select
                    v-model="form.stato_nutrizionale"
                    clearable
                    value-key="item"
                  >
                    <el-option
                      v-for="(item, index) in config.stato_di_nutrizione"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Stato di Coscienza" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.stato_di_coscienza_updated_at, form.stato_di_coscienza_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.stato_di_coscienza" clearable>
                    <el-option
                      v-for="(item, index) in config.stato_di_coscienza"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Orientamento" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.orientamento_spazio_temporale_updated_at, form.orientamento_spazio_temporale_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select
                    v-model="form.orientamento_spazio_temporale"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in config.orientamento"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Respiro" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.respiro_updated_at, form.respiro_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.respiro" clearable>
                    <el-option
                      v-for="(item, index) in config.respiro"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Tipo Tracheostomia" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.tracheostomia_type_updated_at, form.tracheostomia_type_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select
                    v-model="form.tracheostomia_type"
                    clearable
                    :disabled="form.respiro != 5"
                  >
                    <el-option
                      v-for="(item, index) in config.tipo_tracheostomia"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Cannula Tracheostomia" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.tracheostomia_cannula_type_updated_at, form.tracheostomia_cannula_type_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    v-model="form.tracheostomia_cannula_type"
                    :disabled="form.respiro != 5"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Ossigenoterapia" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.ossigenoterapia_updated_at, form.ossigenoterapia_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.ossigenoterapia" clearable>
                    <el-option
                      v-for="(item, index) in config.ossigenoterapia"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Tipo Ossigenoterapia" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.ossigenoterapia_type_updated_at, form.ossigenoterapia_type_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.ossigenoterapia_type" clearable>
                    <el-option
                      v-for="(item, index) in config.tipo_ossigenoterapia"
                      :key="index"
                      :label="item"
                      :value="index"
                      :disabled="form.ossigenoterapia != 0"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Flusso Ossigenoterapia" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.ossigenoterapia_flusso_updated_at, form.ossigenoterapia_flusso_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    v-model="form.ossigenoterapia_flusso"
                    :disabled="form.ossigenoterapia_type != 1"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Secchezza cute" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.secchezza_cute_updated_at, form.secchezza_cute_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.secchezza_cute" clearable>
                    <el-option
                      v-for="(item, index) in config.secchezza_cute"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Cute" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.cute_updated_at, form.cute_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.cute" clearable>
                    <el-option
                      v-for="(item, index) in config.cute"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Sarcopenia" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.sarcopenia_updated_at, form.sarcopenia_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.sarcopenia" clearable>
                    <el-option
                      v-for="(item, index) in config.sarcopenia"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Edemi Declivi" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.edemi_declivi_updated_at, form.edemi_declivi_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.edemi_declivi" clearable>
                    <el-option
                      v-for="(item, index) in config.edemi_declivi"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Astenia" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.astenia_updated_at, form.astenia_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.astenia" clearable>
                    <el-option
                      v-for="(item, index) in config.astenia"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Memoria Recente" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.memoria_recente_updated_at, form.memoria_recente_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.memoria_recente" clearable>
                    <el-option
                      v-for="(item, index) in config.memoria_recente"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Memoria Remota" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.memoria_remota_updated_at, form.memoria_remota_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.memoria_remota" clearable>
                    <el-option
                      v-for="(item, index) in config.memoria_remota"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Collaborante" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.collaborante_updated_at, form.collaborante_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.collaborante" clearable>
                    <el-option
                      v-for="(item, index) in config.collaborante"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Condizioni Generali" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.condizioni_generali_updated_at, form.condizioni_generali_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select
                    v-model="form.condizioni_generali"
                    clearable
                    value-key="item"
                  >
                    <el-option
                      v-for="(item, index) in config.condizioni_generali"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="no-margin-row">
              <el-col :span="24" :md="12">
                <el-form-item label="Credo Religioso" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.credo_religioso_updated_at, form.credo_religioso_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.credo_religioso"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="12">
                <el-form-item
                  label="Disposizione Anticipata di Trattamento (L 219/2017)"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.disp_ant_trat_updated_at, form.disp_ant_trat_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    v-model="form.disp_ant_trat"
                    type="textarea"
                    :rows="4"
                    prefix-icon="fas fa-user"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-divider content-position="left">Fumo</el-divider>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Fumatore/Fumatrice" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.fumo_updated_at, form.fumo_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.fumo" clearable>
                    <el-option
                      v-for="(item, index) in config.fumo"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Da anni" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.fumo_da_anni_updated_at, form.fumo_da_anni_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input-number
                    v-model="form.fumo_da_anni"
                    :min="0"
                    :max="100"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Sigarette al dì" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.fumo_sig_die_updated_at, form.fumo_sig_die_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input-number
                    v-model="form.fumo_sig_die"
                    :min="0"
                    :max="100"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-divider content-position="left">Alcoolici</el-divider>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Potus" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.potus_updated_at, form.potus_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.potus" clearable>
                    <el-option
                      v-for="(item, index) in config.potus"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Vino al giorno" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.vino_die_updated_at, form.vino_die_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input-number
                    v-model="form.vino_die"
                    :min="0"
                    :max="100"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Birra al giorno" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.birra_die_updated_at, form.birra_die_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input-number
                    v-model="form.birra_die"
                    :min="0"
                    :max="100"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Liquori al giorno" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.liquori_die_updated_at, form.liquori_die_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input-number
                    v-model="form.liquori_die"
                    :min="0"
                    :max="100"
                  ></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <!-- tab comportamento -->
          <el-tab-pane label="Comportamento">
            <el-row :gutter="20" class="no-margin-row">
              <el-col :span="8">
                <el-form-item label="Igiene" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.igiene_updated_at, form.igiene_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.igiene" clearable>
                    <el-option
                      v-for="(item, index) in config.igiene"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Vestizione" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.vestizione_updated_at, form.vestizione_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.vestizione" clearable>
                    <el-option
                      v-for="(item, index) in config.vestizione"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Caldo/Freddo" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.temperatura_corporea_updated_at, form.temperatura_corporea_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.temperatura_corporea" clearable>
                    <el-option
                      v-for="(item, index) in config.temperatura_corporea"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Agitazione" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.agitazione_updated_at, form.agitazione_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.agitazione" clearable>
                    <el-option
                      v-for="(item, index) in config.agitazione"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Di Giorno" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.agitazione_giorno_updated_at, form.agitazione_giorno_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-checkbox
                    v-model="form.agitazione_giorno"
                    label="Sì"
                    border
                  ></el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Di Notte" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.agitazione_notte_updated_at, form.agitazione_notte_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-checkbox
                    v-model="form.agitazione_notte"
                    label="Sì"
                    border
                  ></el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="24"></el-col>
              <el-col :span="12">
                <el-form-item label="Rischio di Fuga" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.rischio_fuga_updated_at, form.rischio_fuga_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.rischio_fuga" clearable>
                    <el-option
                      v-for="(item, index) in config.rischio_di_fuga"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Di Giorno" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.rischio_fuga_giorno_updated_at, form.rischio_fuga_giorno_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-checkbox
                    v-model="form.rischio_fuga_giorno"
                    label="Sì"
                    border
                  ></el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Di Notte" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.rischio_fuga_notte_updated_at, form.rischio_fuga_notte_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-checkbox
                    v-model="form.rischio_fuga_notte"
                    label="Sì"
                    border
                  ></el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="24"></el-col>
              <el-col :span="24">
                <el-form-item label="Altro">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.note_comportamento_updated_at, form.note_comportamento_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.note_comportamento"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <!-- tab mobilità -->
          <el-tab-pane label="Mobilità">
            <el-row :gutter="20" class="no-margin-row">
              <el-col :span="12">
                <el-form-item label="Mobilizzazione" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.mobilizzazione_updated_at, form.mobilizzazione_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select
                    v-model="form.mobilizzazione"
                    clearable
                    value-key="item"
                  >
                    <el-option
                      v-for="(item, index) in config.mobilizzazione"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Mobilizzazione igiene e uso del bagno" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.mobilizzazione_igiene_e_bagno_updated_at, form.mobilizzazione_igiene_e_bagno_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    v-model="form.mobilizzazione_igiene_e_bagno"
                    placeholder="Mobilizzazione igiene e uso del bagno"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Equilibrio" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.equilibrio_updated_at, form.equilibrio_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.equilibrio" clearable>
                    <el-option
                      v-for="(item, index) in config.equilibrio"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Carrozzina" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.spostamento_con_carrozzina_updated_at, form.spostamento_con_carrozzina_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select
                    v-model="form.spostamento_con_carrozzina"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in config.spostamento_con_carrozzina"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Letto/Sedia" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.trasferimento_letto_sedia_updated_at, form.trasferimento_letto_sedia_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.trasferimento_letto_sedia" clearable>
                    <el-option
                      v-for="(item, index) in config.trasferimento_letto_sedia"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Mobilizzazione e trasferimenti" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.mobilizzazione_e_trasferimenti_updated_at, form.mobilizzazione_e_trasferimenti_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    v-model="form.mobilizzazione_e_trasferimenti"
                    placeholder="Mobilizzazione e trasferimenti"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Deambulazione" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.deambulazione_updated_at, form.deambulazione_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.deambulazione" clearable>
                    <el-option
                      v-for="(item, index) in config.deambulazione"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Deambulazione al piano" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.deambulazione_piano_updated_at, form.deambulazione_piano_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    v-model="form.deambulazione_piano"
                    placeholder="Deambulazione al piano"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Uso del Bagno" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.uso_del_bagno_updated_at, form.uso_del_bagno_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.uso_del_bagno" clearable>
                    <el-option
                      v-for="(item, index) in config.uso_del_bagno"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Ausili per Bagno settimanale" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.ausili_bagno_settimanale_updated_at, form.ausili_bagno_settimanale_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    v-model="form.ausili_bagno_settimanale"
                    placeholder="Ausili per Bagno settimanale"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Note" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.note_mobilita_updated_at, form.note_mobilita_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    v-model="form.note_mobilita"
                    placeholder="Note"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item class="without-label">
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.mad"
                          label="Posizionato MAD"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.mad_updated_at, form.mad_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <!-- tab alimentare -->
          <el-tab-pane label="Alimentare">
            <el-row :gutter="20" class="no-margin-row">
              <el-col :span="24">
                <el-form-item label="Dieta">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.dieta_updated_at, form.dieta_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.dieta" clearable>
                    <el-option
                      v-for="(item, index) in dietType"
                      :key="index"
                      :label="item"
                      :value="Number(index)"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Note sull'alimentazione"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.alimentazione_note_updated_at, form.alimentazione_note_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input  type="textarea" :rows="4" v-model="form.alimentazione_note"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Preferenze Alimentari" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.preferenze_alimentari_updated_at, form.preferenze_alimentari_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.preferenze_alimentari"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Cavo Orale" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.cavo_orale_updated_at, form.cavo_orale_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.cavo_orale" clearable>
                    <el-option
                      v-for="(item, index) in config.cavo_orale"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Disfagia" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.disfagia_updated_at, form.disfagia_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.disfagia" clearable>
                    <el-option
                      v-for="(item, index) in config.disfagia"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="Autonomia alimentazione"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.autonomia_nella_alimentazione_updated_at, form.autonomia_nella_alimentazione_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select
                    v-model="form.autonomia_nella_alimentazione"
                    clearable
                  >
                    <el-option
                      v-for="(
                        item, index
                      ) in config.autonomia_nella_alimentazione"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Appetito" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.appetito_updated_at, form.appetito_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.appetito" clearable>
                    <el-option
                      v-for="(item, index) in config.appetito"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Idratazione" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.idratazione_updated_at, form.idratazione_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.idratazione" clearable>
                    <el-option
                      v-for="(item, index) in config.idratazione"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="Difficoltà masticazione"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.difficolta_nella_masticazione_updated_at, form.difficolta_nella_masticazione_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select
                    v-model="form.difficolta_nella_masticazione"
                    clearable
                  >
                    <el-option
                      v-for="(
                        item, index
                      ) in config.difficolta_nella_masticazione"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Protesi Dentale" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.protesi_dentale_updated_at, form.protesi_dentale_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.protesi_dentale" clearable>
                    <el-option
                      v-for="(item, index) in config.protesi_dentale"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Nutrizione Artificiale" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.nutrizione_artificiale_updated_at, form.nutrizione_artificiale_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.nutrizione_artificiale" clearable>
                    <el-option
                      v-for="(item, index) in config.nutrizione_artificiale"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Integratori Alimentari" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.integratori_alimentari_updated_at, form.integratori_alimentari_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.integratori_alimentari" clearable>
                    <el-option
                      v-for="(item, index) in config.integratori_alimentari"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <!-- tab abitudinale -->
          <el-tab-pane label="Abitudinale">
            <el-row :gutter="20" class="no-margin-row">
              <el-col :span="24">
                <h3>Abitudini Intestinali</h3>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Frequenza Evacuazioni" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.frequenza_evacuazioni_updated_at, form.frequenza_evacuazioni_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input v-model="form.frequenza_evacuazioni"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="Caratteristica delle Feci"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.caratteristica_feci_updated_at, form.caratteristica_feci_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input v-model="form.caratteristica_feci"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Alvo" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.alvo_updated_at, form.alvo_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.alvo" clearable>
                    <el-option
                      v-for="(item, index) in config.alvo"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Continenza" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.continenza_updated_at, form.continenza_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.continenza" clearable>
                    <el-option
                      v-for="(item, index) in config.continenza_intestinale"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" :md="8">
                <el-form-item class="without-label">
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.lassativi"
                          label="Utilizzo Lassativi"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.lassativi_updated_at, form.lassativi_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="12" :md="8">
                <el-form-item class="without-label">
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.enteroclismi"
                          label="Utilizzo Enteroclismi"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.enteroclismi_updated_at, form.enteroclismi_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="12" :md="8">
                <el-form-item class="without-label">
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.stomie"
                          label="Utilizzo Stomie"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.stomie_updated_at, form.stomie_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Altro" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.abitudini_intestinali_altro_updated_at, form.abitudini_intestinali_altro_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.abitudini_intestinali_altro"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <h3>Abitudini Urinarie</h3>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Frequenza Urinaria" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.frequenza_urinaria_updated_at, form.frequenza_urinaria_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input v-model="form.frequenza_urinaria"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="Caratteristica delle Urine"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.caratteristica_urine_updated_at, form.caratteristica_urine_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input v-model="form.caratteristica_urine"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Funzione Vescicale" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.funzione_vescicale_updated_at, form.funzione_vescicale_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.funzione_vescicale" clearable>
                    <el-option
                      v-for="(item, index) in config.funzione_vescicale"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Continenza Vescicale" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.continenza_vescicale_updated_at, form.continenza_vescicale_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.continenza_vescicale" clearable>
                    <el-option
                      v-for="(item, index) in config.continenza_urinaria"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item class="without-label">
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.catetere_vescicale"
                          label="Catetere Vescicale"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.catetere_vescicale_updated_at, form.catetere_vescicale_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Altro">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.abitudini_urinarie_altro_updated_at, form.abitudini_urinarie_altro_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.abitudini_urinarie_altro"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <h3>Abitudini del Sonno</h3>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Orario messa a letto" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.orario_messa_a_letto_updated_at, form.orario_messa_a_letto_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-time-picker
                    v-model="form.orario_messa_a_letto"
                    :editable="false"
                    :arrow-control="true"
                    value-format="HH:mm"
                    format="HH:mm"
                  ></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Riposo post-prandiale" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.riposo_post_prandiale_updated_at, form.riposo_post_prandiale_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.riposo_post_prandiale" clearable>
                    <el-option
                      v-for="(item, index) in config.riposo_post_prandiale"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.sonno_regolare"
                          label="Sonno Regolare"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.sonno_regolare_updated_at, form.sonno_regolare_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.risveglio_precoce"
                          label="Risveglio Precoce"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.risveglio_precoce_updated_at, form.risveglio_precoce_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.addormentamento_tardivo"
                          label="Sonno Tardivo"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.addormentamento_tardivo_updated_at, form.addormentamento_tardivo_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.insonnia"
                          label="Insonnia"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.insonnia_updated_at, form.insonnia_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Altro" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.abitudini_del_sonno_altro_updated_at, form.abitudini_del_sonno_altro_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.abitudini_del_sonno_altro"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <h3>Linguaggio</h3>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.linguaggio_normale"
                          label="Normale"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.linguaggio_normale_updated_at, form.linguaggio_normale_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.linguaggio_afasia"
                          label="Afasia"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.linguaggio_afasia_updated_at, form.linguaggio_afasia_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.linguaggio_disartria"
                          label="Disartria"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.linguaggio_disartria_updated_at, form.linguaggio_disartria_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.linguaggio_anomie"
                          label="Anomie"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.linguaggio_anomie_updated_at, form.linguaggio_anomie_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="24"></el-col>
              <el-col :span="24">
                <el-form-item label="Altro" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.note_linguaggio_updated_at, form.note_linguaggio_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.note_linguaggio"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <h3>Sensoriale</h3>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Udito" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.udito_updated_at, form.udito_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.udito" clearable>
                    <el-option
                      v-for="(item, index) in config.udito"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Protesi Acustica" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.protesi_acustica_sx_updated_at, form.protesi_acustica_sx_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-checkbox
                    v-model="form.protesi_acustica_sx"
                    label="Sinistra"
                    border
                  ></el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Protesi Acustica" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.protesi_acustica_dx_updated_at, form.protesi_acustica_dx_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-checkbox
                    v-model="form.protesi_acustica_dx"
                    label="Destra"
                    border
                  ></el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="24"></el-col>
              <el-col :span="12">
                <el-form-item label="Vista" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.vista_updated_at, form.vista_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.vista" clearable>
                    <el-option
                      v-for="(item, index) in config.vista"
                      :key="index"
                      :label="item"
                      :value="Number(index)"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Olfatto" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.olfatto_updated_at, form.olfatto_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.olfatto" clearable>
                    <el-option
                      v-for="(item, index) in config.olfatto"
                      :key="index"
                      :label="item"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24"></el-col>
              <el-col :span="24">
                <el-form-item label="Altro" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.note_sensoriale_updated_at, form.note_sensoriale_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.note_sensoriale"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <!-- tab patologica -->
          <el-tab-pane label="Patologica">
            <el-row :gutter="20" class="no-margin-row">
              <el-col :span="24">
                <h3>Positività</h3>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.positivita_hcv"
                          label="HCV"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.positivita_hcv_updated_at, form.positivita_hcv_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.positivita_hbv"
                          label="HBV"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.positivita_hbv_updated_at, form.positivita_hbv_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.positivita_hav"
                          label="HAV"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.positivita_hav_updated_at, form.positivita_hav_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.positivita_hiv"
                          label="HIV"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.positivita_hiv_updated_at, form.positivita_hiv_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.positivita_klebsiella"
                          label="Klebsiella"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.positivita_klebsiella_updated_at, form.positivita_klebsiella_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.positivita_clostridium"
                          label="Clostridium"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.positivita_clostridium_updated_at, form.positivita_clostridium_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="24"></el-col>
              <el-col :span="24">
                <el-form-item label="Altro" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.note_positivita_updated_at, form.note_positivita_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.note_positivita"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <h3>Pacemaker</h3>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Portatore di Pacemaker" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.pacemaker_updated_at, form.pacemaker_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-select v-model="form.pacemaker" clearable>
                    <el-option
                      v-for="(item, index) in config.portatore_di_pacemaker"
                      :key="index"
                      :label="item"
                      :value="Number(index)"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="Applicato in data"
                  prop="date"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.pacemaker_apply_date_updated_at, form.pacemaker_apply_date_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-date-picker
                    v-model="form.pacemaker_apply_date"
                    type="date"
                    placeholder="Scegli data"
                    :editable="false"
                    :picker-options="{
                      firstDayOfWeek: 1,
                    }"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="Da sostituire in data"
                  prop="date"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.pacemaker_replace_date_updated_at, form.pacemaker_replace_date_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-date-picker
                    v-model="form.pacemaker_replace_date"
                    type="date"
                    placeholder="Scegli data"
                    :editable="false"
                    :picker-options="{
                      firstDayOfWeek: 1,
                    }"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <h3>Patologie</h3>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Anamnesi Patologica Generale"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.apparato_altro_patologico_updated_at, form.apparato_altro_patologico_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.apparato_altro_patologico"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Apparato Cardiocircolatorio"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.apparato_cardiocircolatorio_patologico_updated_at, form.apparato_cardiocircolatorio_patologico_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.apparato_cardiocircolatorio_patologico"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Apparato Respiratorio" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.apparato_respiratorio_patologico_updated_at, form.apparato_respiratorio_patologico_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.apparato_respiratorio_patologico"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Apparato Nervoso e Psiche"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.apparato_nervoso_patologico_updated_at, form.apparato_nervoso_patologico_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.apparato_nervoso_patologico"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Apparato Gastroenterico"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.apparato_gastroenterico_patologico_updated_at, form.apparato_gastroenterico_patologico_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.apparato_gastroenterico_patologico"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Apparato Urogenitale" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.apparato_urogenitale_patologico_updated_at, form.apparato_urogenitale_patologico_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.apparato_urogenitale_patologico"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Apparato Osteoarticolare"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.apparato_osteoarticolare_patologico_updated_at, form.apparato_osteoarticolare_patologico_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.apparato_osteoarticolare_patologico"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Apparato Endocrino" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.apparato_endocrino_patologico_updated_at, form.apparato_endocrino_patologico_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.apparato_endocrino_patologico"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Cute (annessi cutanei e mucose)"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.apparato_cute_patologico_updated_at, form.apparato_cute_patologico_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.apparato_cute_patologico"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Organi di senso" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.apparato_organi_di_senso_patologico_updated_at, form.apparato_organi_di_senso_patologico_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.apparato_organi_di_senso_patologico"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <!-- tab animazione -->
          <el-tab-pane label="Animazione">
            <el-row :gutter="20" class="no-margin-row">
              <el-col :span="24">
                <el-form-item
                  label="Luoghi in cui ha vissuto"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.luoghi_vissuti_updated_at, form.luoghi_vissuti_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.luoghi_vissuti"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Con chi viveva prima dell'inserimento"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.conviventi_prima_del_inserimento_updated_at, form.conviventi_prima_del_inserimento_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.conviventi_prima_del_inserimento"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Composizione del nucleo familiare"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.composizione_nucleo_familiare_updated_at, form.composizione_nucleo_familiare_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.composizione_nucleo_familiare"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Relazioni affettive significative"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.relazioni_affettive_significative_updated_at, form.relazioni_affettive_significative_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.relazioni_affettive_significative"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Studi effettuati" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.studi_effettuati_updated_at, form.studi_effettuati_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.studi_effettuati"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Professione esercitata" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.professione_esercitata_updated_at, form.professione_esercitata_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.professione_esercitata"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Capacità lavorativa residua"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.capacita_lavorativa_residua_updated_at, form.capacita_lavorativa_residua_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.capacita_lavorativa_residua"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Hobbie ed interessi" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.hobbi_e_interessi_updated_at, form.hobbi_e_interessi_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.hobbi_e_interessi"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Atteggiamento dei familiari"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.atteggiamento_dei_familiari_updated_at, form.atteggiamento_dei_familiari_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.atteggiamento_dei_familiari"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24"></el-col>
              <el-col :span="24">
                <el-form-item label="Altro" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.note_animazione_updated_at, form.note_animazione_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.note_animazione"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <h3>Capacità di socializzazione</h3>
              </el-col>
              <el-col :span="24" :md="8">
                <el-form-item>
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.fa_amicizia_facilmente"
                          label="Fa amicizia facilmente"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.fa_amicizia_facilmente_updated_at, form.fa_amicizia_facilmente_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="8">
                <el-form-item>
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.amicizie_passate"
                          label="Amicizie passate mantenute"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.amicizie_passate_updated_at, form.amicizie_passate_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="8">
                <el-form-item>
                    <div style="display: flex; align-items: center;">
                        <el-checkbox
                          v-model="form.nuove_amicizie"
                          label="Si è fatto nuovi amici"
                          border
                        >
                        </el-checkbox>
                          <el-tooltip
                            effect="dark"
                           :content="formattedField(form.nuove_amicizie_updated_at, form.nuove_amicizie_updated_by)"
                          ><i class="el-icon-info" style="margin-left: 5px;"></i>
                          </el-tooltip>
                    </div>
                </el-form-item>
              </el-col>
              <el-col :span="24"></el-col>
              <el-col :span="24">
                <el-form-item label="Altro" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.note_capacita_socializzazione_updated_at, form.note_capacita_socializzazione_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.note_capacita_socializzazione"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" style="display: flex; align-items: center;">
                <h3>Reazioni al contatto</h3>
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.reazioni_al_contatto_updated_at, form.reazioni_al_contatto_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
              </el-col>
              <el-radio-group v-model="form.reazioni_al_contatto">
                <el-col :span="12">
                  <el-form-item>
                    <el-radio :label="1" border>Docilità</el-radio>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item>
                    <el-radio :label="2" border>Diffidenza</el-radio>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item>
                    <el-radio :label="3" border>Ostilità</el-radio>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item>
                   <el-radio :label="4" border>Indifferenza</el-radio>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item>
                    <el-radio :label="5" border>Non valutabile</el-radio>
                  </el-form-item>
                </el-col>
              </el-radio-group>
              <el-col :span="24">
                <el-form-item
                  label="Scansione della giornata"
                  class="long-label"
                >
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.scansione_della_giornata_updated_at, form.scansione_della_giornata_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.scansione_della_giornata"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Altro" class="long-label">
                  <template>
                    <el-tooltip
                      effect="dark"
                      :content="formattedField(form.predisposizioni_altro_updated_at, form.predisposizioni_altro_updated_by)"
                    >
                    <i class="el-icon-info" style="margin-left: 5px;"></i>
                    </el-tooltip>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="form.predisposizioni_altro"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <!-- tab scheda infermieristica: apre modale -->
          <el-tab-pane
            label="Scheda Infermieristica"
            name="scheda_infermieristica"
            v-if="editableRegistryId == registry.id"
          >
            <!-- @click.prevent="openModal" -->
            <template #label>
              <el-button type="primary" @click.prevent="openModal" size="small"
                >Scheda Infermieristica</el-button
              >
            </template>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
import Patient from "@/js/api/services/Patient";
import NursingCard from "@/js/pages/Patients/components/NursingCard.vue";
import User from "@/js/api/services/User";
import moment from "moment";

export default {
  name: "Anamnesi",
  props: ["profile_id"],
  components: { NursingCard },
  data() {
    return {
      data: {},
      registry: {},
      patient: {},
      dietType: {},
      loading: true,
      submitLoading: false,
      form: {},
      protections: {},
      woundsDecubito: {},
      woundsOthers: {},
      showNursingCard: false,
      testMustResult: "Test non eseguito",
      editableRegistryId: null,
      showPatientAlert: false,
      users: null,
    };
  },
  computed: {
    config() {
      return this.$store.state.config.anagrafica;
    },
    user() {
      return this.$store.state.user;
    },
    professions() {
      return this.$store.state.professions;
    },
  },
  methods: {
    formattedField(field, userId) {
      const user = this.getOperatorById(userId);

      return 'Ultima modifica: ' + (field ? moment(field).format('DD/MM/YYYY HH:mm') + ' - '
        + (user ? user.full_name + ' (' + this.professions[user.profession_id].label + ')' : '') : 'Mai');
    },
    getOperatorById(userId) {
     if(this.users && userId) {
      const usersArray = Object.values(this.users.data.operators).flat();
      const user = usersArray.find(op => op.id === userId);

      return user;
     }
    },
    updateBMI() {
      this.form.bmi = (!this.form.altezza || this.form.altezza == 0) ? 0
        : (this.form.peso / Math.pow(this.form.altezza / 100, 2)).toFixed(2);
      (this.form.altezza !== 0 && this.form.altezza < 120) ? this.showPatientAlert = true : this.showPatientAlert = false;
    },
    async fetch(dateTime) {
      this.loading = true;
      let params = {
        date_time: dateTime,
      };
      const response = await Patient.registry(this.profile_id, params);
      this.data = response.data;
      this.registry = response.data.items;
      this.patient = response.data.patient;
      this.dietType = response.data.dietType;

      var wounds = response.data.wounds;
      this.woundsDecubito = _.filter(
        wounds,
        (item) => item.diagnosi_etiologica === "2"
      );
      this.woundsOthers = _.filter(
        wounds,
        (item) => item.diagnosi_etiologica !== "2"
      );
      this.protections = response.data.protections;
      let must = response.data.must;

      if (must)
        this.testMustResult =
          "Esito: " +
          must.total_description +
          " (punteggio: " +
          must.total_string +
          ") - eseguito il: " +
          must.clean_date_time_test;
      else this.testMustResult = "Test non eseguito";

      this.form = this.registry;

      if (!dateTime) {
        this.$store.commit("updatePatientRegistry", this.registry);
      }

      this.form.agitazione_giorno = this.form.agitazione_giorno == 1;
      this.form.agitazione_notte = this.form.agitazione_notte == 1;
      this.form.rischio_fuga_giorno = this.form.rischio_fuga_giorno == 1;
      this.form.rischio_fuga_notte = this.form.rischio_fuga_notte == 1;
      this.form.fa_amicizia_facilmente = this.form.fa_amicizia_facilmente == 1;
      this.form.amicizie_passate = this.form.amicizie_passate == 1;
      this.form.nuove_amicizie = this.form.nuove_amicizie == 1;

      this.form.positivita_hcv = this.form.positivita_hcv == 1;
      this.form.positivita_hbv = this.form.positivita_hbv == 1;
      this.form.positivita_hav = this.form.positivita_hav == 1;
      this.form.positivita_hiv = this.form.positivita_hiv == 1;
      this.form.positivita_klebsiella = this.form.positivita_klebsiella == 1;
      this.form.positivita_clostridium = this.form.positivita_clostridium == 1;
      this.form.mad = this.form.mad == 1;
      this.form.lassativi = this.form.lassativi == 1;
      this.form.enteroclismi = this.form.enteroclismi == 1;
      this.form.stomie = this.form.stomie == 1;
      this.form.catetere_vescicale = this.form.catetere_vescicale == 1;
      const boolProps = [
        "sonno_regolare",
        "risveglio_precoce",
        "addormentamento_tardivo",
        "insonnia",
        "linguaggio_normale",
        "linguaggio_afasia",
        "linguaggio_disartria",
        "linguaggio_anomie",
        "protesi_acustica_sx",
        "protesi_acustica_dx",
      ];
      boolProps.forEach(
        (prop) => (this.form[prop] = Boolean(this.registry[prop]))
      );
      this.loading = false;
    },
    submit() {
      this.$confirm("Salvare le modifiche?", "Attenzione", {
        confirmButtonText: "Sì",
        cancelButtonText: "Annulla",
        type: "success",
      })
        .then(() => {
          this.submitLoading = true;
          this.showNursingCard = false;
          this.store();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Operazione annullata",
          });
        });
    },
    async store() {
      try {
        await Patient.patch(this.registry.id, this.form);
        this.success();
      } catch (error) {
        this.$alert("E' stata rilevata una nuova Anamnesi compilata, vi invitiamo a ricaricare la pagina!", 'ATTENZIONE', {
          confirmButtonText: 'Ricarica pagina',
          callback: async action => {
            if (action === 'confirm') {
                await this.fetch();
                this.editableRegistryId = this.registry.id;
                this.$notify.info({
                  title: 'Info',
                  message: 'L\'Anamnesi più recente è adesso visualizzata.'
                });
            } else {
                this.$message({
                    type: "info",
                    message: "Operazione annullata",
                });
            }
          }
        });
        this.submitLoading = false;
      }
    },
    async success() {
      await this.fetch();
      this.editableRegistryId = this.registry.id;
      this.$store.dispatch("fetchPatients");
      this.$emit("anamnesiUpdate");
      this.submitLoading = false;
    },
    openModal(tabPane) {
      if (tabPane == "scheda_infermieristica") {
        this.showNursingCard = true;
      }
    },
    closeModal({ tabPane, action }) {
      if (tabPane == "scheda_infermieristica") {
        this.showNursingCard = false;
      }
      if (action == "reset") this.fetch();
    },
    async cancel() {
      await this.$confirm(
        `Annullare anamnesi del ${this.data.date_time_current}?`,
        "Attenzione",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Annulla",
          type: "warning",
        }
      )
        .then(() => {
          Patient.registryDestroy(this.registry.id)
            .then(async () => {
              await this.fetch();
              this.editableRegistryId = this.registry.id;
            })
            .catch((error) => {
              this.submitLoading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Operazione annullata",
          });
        });
    },
  },
  async mounted() {
    await this.fetch(null);
    let params = {
      structureId: this.$store.state.structureId,
    };
    this.users = await User.fetch(params);
    this.editableRegistryId = this.registry.id;

    const triggerEl = this.$el.querySelector(".trigger-intercept") ?? null;
    const tabsEl = this.$el.querySelector(".el-tabs.is-sticky") ?? null;
    const tabContent = tabsEl.querySelector(".el-tabs__content") ?? null;
    const options = {
      root: null,
      threshold: 1,
      rootMargin: "50px 0px 0px 0px",
    };

    const observer = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        // change value of --tabContentWidth with the width of .el-tabs__content
        $("body")
          .get(0)
          .style.setProperty(
            "--tabContentWidth",
            tabContent.clientWidth + "px"
          );
        if (entry.isIntersecting) {
          tabsEl.classList.remove("active");
        } else {
          tabsEl.classList.add("active");
        }
      }
    }, options);

    observer.observe(triggerEl);
  },
};
</script>
<style scoped>
.titleCol {
  padding-top: 9px;
}

.title {
  font-size: 20px;
}

.el-checkbox,
.el-radio {
  width: 100%;
}

.margin-top {
  margin-top: 40px;
}

.el-tabs {
  height: 100% !important;
}

.el-input-number {
  width: 100% !important;
}

div#tab-scheda_infermieristica {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #dcdfe6;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  font-weight: 500;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
  background-color: #67c23a;
  border-color: #67c23a;
  color: #fff;
}
@media screen and (max-width: 1023px) {
    .el-tooltip.el-icon-info {
        display: flex;
        padding-left: 5px;
    }
}
@media screen and (min-width: 992px) {
    .without-label {
        margin-top: 40px;
    }
}
</style>
