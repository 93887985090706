<template>
  <div>
    <el-button
      v-show="showBackButton"
      class="float-right add-button no-broadcast"
      type="success"
      @click="backToPai()"
      >Torna all'ospite</el-button
    >
    <el-form
      label-position="top"
      label-width="80px"
      :model="indexForm"
      ref="indexForm"
    >
      <el-row class="form-header" :gutter="5">
        <el-col :span="8">
          <el-form-item label="Data" prop="date">
            <el-date-picker
              v-model="indexForm.date"
              :editable="false"
              type="date"
              :picker-options="pickerOptions"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
              @change="activeSelectTest()"
            ></el-date-picker>
            <!-- placeholder="Scegli data" -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Orario" prop="time">
            <el-time-picker
              v-model="indexForm.time"
              :editable="false"
              format="HH:mm"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="activeSelectTest()"
            ></el-time-picker>
            <!-- placeholder="Seleziona Orario" -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Ospite" prop="patient_id">
            <el-select
              v-model="indexForm.patient_id"
              placeholder="Seleziona Ospite"
              filterable
              clearable
              @clear="onPatientCleared"
              @change="activeSelectTest()"
            >
              <el-option
                v-for="item in patients"
                :key="item.id"
                :label="item.full_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="block with-form-header">
        <el-col :span="24">
            <span v-if="showAbsentPatientAlert" style="float: right">
                <font-awesome-icon icon="exclamation" class="danger-text" />
                <font style="color: red"
                >Attenzione: l'ospite è attualmente assente</font
                >
            </span>
            <span v-if="showOldAbsencePatientAlert" style="float: right">
                <font-awesome-icon icon="exclamation" class="danger-text" />
                <font style="color: red"
                    >Attenzione: l'ospite era assente in questo periodo</font
                >
            </span>
          <el-form-item label="Scala di Valutazione" prop="type">
            <el-select
              v-model="type"
              placeholder="Seleziona Scala di Valutazione / Test"
              filterable
              :disabled="disableChangeTest"
            >
              <el-option
                v-for="item in testConfig"
                :key="item['idModel']"
                :label="item['name']"
                :value="item['idModel']"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-loading="loading">
          <component
            :is="activeTest"
            v-if="isVisible"
            @submitTest="storeTest"
            :loading.sync="loading"
            :patient-name="patientName"
            :patient-id="patientId"
            :patient-registry="patientRegistry"
          ></component>
        </el-col>
      </div>
    </el-form>
  </div>
</template>
<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Mmse from "@/js/pages/Tests/Scale/Mmse";
import Braden from "@/js/pages/Tests/Scale/Braden";
import Conley from "@/js/pages/Tests/Scale/Conley";
import Gds from "@/js/pages/Tests/Scale/Gds";
import Gds5 from "@/js/pages/Tests/Scale/Gds5";
import Spmsq from "@/js/pages/Tests/Scale/Spmsq";
import Nrs from "@/js/pages/Tests/Scale/Nrs";
import Vas from "@/js/pages/Tests/Scale/Vas";
import Painad from "@/js/pages/Tests/Scale/Painad";
import Guss from "@/js/pages/Tests/Scale/Guss";
import Bina from "@/js/pages/Tests/Scale/Bina";
import Kane from "@/js/pages/Tests/Scale/Kane";
import Mna from "@/js/pages/Tests/Scale/Mna";
import Barthel from "@/js/pages/Tests/Scale/Barthel";
import Tinetti from "@/js/pages/Tests/Scale/Tinetti";
import Iadl from "@/js/pages/Tests/Scale/Iadl";
import Adico from "@/js/pages/Tests/Scale/Adico";
import Dmi from "@/js/pages/Tests/Scale/Dmi";
import Glasgow from "@/js/pages/Tests/Scale/Glasgow";
import Ashworth from "@/js/pages/Tests/Scale/Ashworth";
import Norton from "@/js/pages/Tests/Scale/Norton";
import Cdrs from "@/js/pages/Tests/Scale/Cdrs";
import Drs from "@/js/pages/Tests/Scale/Drs";
import Cmai from "@/js/pages/Tests/Scale/Cmai";
import Cirs from "@/js/pages/Tests/Scale/Cirs";
import Must from "@/js/pages/Tests/Scale/Must";
import Mnae from "@/js/pages/Tests/Scale/Mnae";
import Npi from "@/js/pages/Tests/Scale/Npi";
import Rss from "@/js/pages/Tests/Scale/Rss";
import Adl from "@/js/pages/Tests/Scale/Adl";
import Rcns from "@/js/pages/Tests/Scale/Rcns";
import Vrs from "@/js/pages/Tests/Scale/Vrs";
import Aged from "@/js/pages/Tests/Scale/Aged";
import Stratify from "@/js/pages/Tests/Scale/Stratify";
import Vcc from "@/js/pages/Tests/Scale/Vcc";

export default {
  name: "Tests",
  components: {
    Mmse,
    Conley,
    Gds,
    Gds5,
    Braden,
    Spmsq,
    Nrs,
    Vas,
    Painad,
    Guss,
    Bina,
    Kane,
    Mna,
    Barthel,
    Tinetti,
    Iadl,
    Adico,
    Dmi,
    Glasgow,
    Ashworth,
    Norton,
    Cdrs,
    Drs,
    Cmai,
    Cirs,
    Must,
    Mnae,
    Npi,
    Rss,
    Adl,
    Rcns,
    Vrs,
    Aged,
    Stratify,
    Vcc,
  },
  data() {
    return {
      loading: false,
      type: null,
      testConfig: {},
      indexForm: {
        date: new Date(),
        time: new Date(),
      },
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      activeTest: null,
      isVisible: false,
      disableChangeTest: true,
      patientRegistry: {},
      showBackButton: false,
      showAbsentPatientAlert: false,
      showOldAbsencePatientAlert: false,
    };
  },
  computed: {
    patients() {
      return this.$store.state.patients;
    },
    patient() {
      return this.indexForm.patient_id;
    },
  },
  methods: {
    async init() {
      const response = await Test.getTestConfig();
      this.testConfig = response.data.tests_config;
    },
    activeSelectTest() {
      if (
        this.disableChangeTest == true &&
        this.indexForm.date &&
        this.indexForm.time &&
        this.indexForm.patient_id
      ) {
        this.disableChangeTest = false;
      }
    },
    storeTest(formTest, formResult) {
      this.loading = true;
      let params = {
        patient_id: this.indexForm.patient_id,
        date: moment(this.indexForm.date).format("YYYY-MM-DD"),
        time: moment(this.indexForm.time).format("HH:mm:ss"),
        type: this.type,
        total: formTest.total,
        total_quest: formTest.total_quest,
        result: formResult,
      };
      Test.store(params)
        .then(this.success)
        .catch(this.error)
        .finally(() => (this.loading = false));
    },
    success() {
      this.$emit("success");
      this.close();
      if(this.$route.query.type) {
        this.backToPai();
      }
    },
    error(error) {
      // gestire possibili errori
    },
    close() {
      this.activeTest = null;
      this.type = null;
      this.indexForm = {
        date: new Date(),
        time: new Date(),
      };
      this.patientRegistry = {};
      this.isVisible = false;
      this.disableChangeTest = true;
    },
    findById(id) {
      return [...this.patients].find((x) => x.id == id);
    },
    resetOnPatientChange() {
      this.type = undefined;
      this.activeTest = null;
    },
    onPatientCleared() {
      this.disableChangeTest = true;
    },
    backToPai() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
  },
  watch: {
    type: function (val) {
      if (val) {
        var model = val.split("_");
        // [0] id | [1] model
        this.activeTest = model[1];
        this.patientRegistry = this.findById(this.indexForm.patient_id);
        this.isVisible = true;
      }
    },
    indexForm: {
      handler: function () {
        if (this.type) this.resetOnPatientChange();
        let idToFind = this.indexForm.patient_id;
        this.patientName = _.get(this.findById(idToFind), "name", "--");
        this.patientId = idToFind;

        if(this.patient){
            var patient = _.filter(this.patients, { id: this.patient })[0];
            this.showAbsentPatientAlert = patient.absence_info.absence_status;
            this.showOldAbsencePatientAlert = false;
            if (!this.showAbsentPatientAlert && patient.absence_info.last_absence){
                var date = `${moment(this.indexForm.date).format("YYYY-MM-DD")} ${moment(this.indexForm.time).format("HH:mm:ss")}`;
                if (date > patient.absence_info.last_absence['confirmation_date'] && date < patient.absence_info.last_absence['verification_date']) {
                    this.showOldAbsencePatientAlert = true;
                }
            }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  beforeMount() {
    if (!_.isUndefined(this.$route.params.patientId)) {
      this.indexForm.patient_id = parseInt(this.$route.params.patientId);
      let idToFind = this.indexForm.patient_id;
      this.patientName = _.get(this.findById(idToFind), "name", "--");
      this.patientId = idToFind;
      this.showBackButton = true;
      this.disableChangeTest = false;
    }
    if(this.$route.query.type) {
        this.type = this.$route.query.type;
    }
  },
};
</script>
